* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
  background: rgba(31, 33, 36, 1);
}

html {
  font-size: 62.5%;
}

h1 {
  font-size: 2.6rem;
}

a {
  color: white;
  font-weight: 400;
  font-size: 2rem;
  text-decoration: none;
}

li, button, label, input, p {
  font-size: 2rem;
  letter-spacing: 2pt;
  color: lightgrey;
  letter-spacing: 1pt;
}

h2 {
  letter-spacing: 10pt;
  font-size: 6.8rem;
  font-weight: 600;
}

h3 {
  padding: 1rem;
  font-size: 3rem;
  color: white;
  font-weight: 300;
  letter-spacing: 2pt;
}

h4, h5 {
  font-size: 2.8rem;
}

select {
  width: 100%;
  padding: 1rem 1rem;
  border: 1px solid white;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  margin: 1rem;
}

select :focus {
  outline: 0;
}

link {
  text-decoration: none;
}

.spinner {
  background: url("./img/icons8-iphone-spinner-240.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  padding: 5rem;
  animation: spinnerAnimation 2s infinite ease-in-out;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.liveButton {
  background-image: url("./img/icons8-play-button-circled.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  margin-top: 10rem;
  margin-bottom: 1rem;
  border: 0;
  z-index: 1;
}

.liveButtonPressed {
  background-image: url("./img/icons8-play-button-circled.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  margin-top: 10rem;
  margin-bottom: 1rem;
  border: 0;
  z-index: 1;
}

.liveButtonLoading {
  background-image: url("./img/icons8-play-button-circled.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  margin-top: 10rem;
  margin-bottom: 1rem;
  border: 0;
  z-index: 1;
  /* transition: transform 0.3s; */
  animation: pulse 0.5s infinite ease-in-out;
}

button {
  padding: 2rem 4rem;
  background: #666a86;
  border: none;
  color: white;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  transition: transform 0.3s;
  animation: buttonAnimation 0.5s forwards ease-in-out;
}

.liveButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.liveButtonPressed:hover {
  opacity: 0.8;
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
}

button:focus {
  outline: 0;
}

@keyframes buttonAnimation {
  from {
    background: white;
    transform: scale(1);
  }
  to {
    background: #00e3f3;
    opacity: 1;
    transform: scale(1.2);
  }
}

/* Header And Nav */

.site-header {
  background-color: rgba(31, 33, 36, 1);
  padding: 1rem;
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  min-height: 5vh;
  top: 0;
}

.nav {
  background-color: rgba(41, 43, 46, 1);
  padding: 1rem;
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  min-height: 5vh;
  position: fixed;
  z-index: 2;
  top: 0;
}

nav ul {
  display: flex;
  flex: 1 1 40rem;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  text-align: center;
}

nav li {
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
}

nav li:hover {
  color: #00e3f3;
  cursor: pointer;
}

#logo {
  font-size: 2rem;
  flex: 1 1 40rem;
  display: flex;
  justify-content: flex-start;
}

.full-logo {
  background: url("./img/THREHSOLDLOGO.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  width: 50%;
  height: 3rem;
  margin: auto;
}

.socialIcons {
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 2rem;
  margin-right: 1rem;
  border: 0;
}

.socialIcons:hover {
  opacity: 0.7;
  cursor: pointer;
}

#instaIcon {
  background: url("./img/icons8-instagram-64.png");
  background-size: cover;
  background-position: center;
  border: 0;
}

#faceIcon {
  background: url("./img/icons8-facebook-64.png");
  background-size: cover;
  background-position: center;
  border: 0;
}

#discordIcon {
  background: url("./img/icons8-discord-64.png");
  background-size: cover;
  background-position: center;
  border: 0;
}

#patreonIcon {
  background: url("./img/icons8-patreon-64.png");
  background-size: cover;
  background-position: center;
  border: 0;
}

#soundcloudIcon {
  background: url("./img/icons8-soundcloud-100_.png");
  background-size: cover;
  background-position: center;
  border: 0;
}

#shareIcon {
  background: url("./img/icons8-share-100.png");
  background-size: cover;
  background-position: center;
  border: 0;
}

.iconLinks {
  height: 2.5rem;
  width: 2.5rem;
}

/* Hero */

.hero {
  min-height: 87vh;
  background-image: linear-gradient( to bottom, rgba(31, 33, 35, 1), rgba(31, 33, 35, 0.5), rgba(31, 33, 35, 1)), url("./img/dumpsterFire.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px rgb(53, 52, 52) solid;
}

.hero h1 {
  font-size: 10rem;
}

.hero button {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

#nowPlaying {
  color: white !important;
  width: 90%;
  margin-top: 2rem;
}

#nowPlayingDetails {
  font-size: 1.5rem;
  width: 70%;
  letter-spacing: 1pt;
  margin-top: 1rem;
  color: lightgrey;
}

@keyframes pulse {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.6) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* SCHEDULE */

.schedule {
  background: rgba(31, 33, 36, 1);
  background-color: rgba(31, 33, 36, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-bottom: 5rem;
  border-bottom: 1px rgb(53, 52, 52) solid;
}

.schedule h1 {
  font-size: 10rem;
}

.scheduleText {
  text-align: center;
}

.schedule p {
  letter-spacing: 1px;
  padding: 1rem;
}

.displaySchedule {
  margin-top: 20px;
}

/* Player */

.playerDiv {
  min-height: 100vh;
}

.player-wrapper {
  background: rgba(41, 43, 46, 1);
  width: 100%;
  min-height: 8vh;
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  bottom: 0;
}

.player-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-wrapper p {
  color: lightgrey;
  font-size: 1.5rem;
  margin-left: 2rem;
  width: 70px;
  text-align: center;
}

.playerPlayButton {
  mask: url("./img/icons8-circled-play.svg");
  padding: 2rem;
  margin-left: 2rem;
  background-color: white;
  border: 0;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-position: center;
}

.playerPauseButton {
  mask: url("./img/icons8-pause-button.svg");
  margin-left: 2rem;
  padding: 2rem;
  background-color: white;
  color: white;
  border: 0;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-position: center;
}

.playerPlayButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.playerPauseButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.playerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 50px;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: left;
}

.playerInfo p {
  font-size: 1.4rem;
  width: 100%;
  text-align: left;
  overflow: hidden;
}

.slider {
  -webkit-appearance: none;
  width: 30%;
  height: 2px;
  border-radius: 2px;
  background: lightgray;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-left: 2rem;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: 0.2s;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
  transform: scale(1.5);
  background: rgb(73, 73, 73);
}

#htmlPlayer {
  display: none;
}

/* Patreon And Discord Section */

.outsideLinks {
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  flex: wrap;
  background-color: rgb(31, 33, 35);
  border-bottom: 1px rgb(53, 52, 52) solid;
}

.outsideLinksSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  background-color: rgb(31, 33, 35);
  margin-top: 2.5rem;
  width: 100%;
}

.outsideLinksSection p {
  font-size: 1.5rem;
  width: 70%;
  letter-spacing: 1pt;
  margin-top: 1rem;
  color: lightgrey;
}

.outsideLinksSection #icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.outsideLinksButtons {
  max-width: 22.5rem;
  max-height: 5rem;
  width: auto;
  height: auto;
  margin: 2rem;
}

.outsideLinksButtons:hover {
  opacity: 0.8;
}

/* Archive */

.archive {
  min-height: 92vh;
  background-color: rgb(31, 33, 35);
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.archive h3 {
  margin-top: 2.5rem;
}

.archive h1 {
  font-size: 10rem;
}

.archiveText {
  text-align: left;
}

#limit {
  width: 10rem;
}

#hostName {
  width: 20%;
  padding: 1rem 1rem;
  border-radius: 4px;
  background: transparent;
  color: black;
  margin: 1rem;
  text-align: left;
}

.episodeCard {
  border-bottom: 1px solid rgb(94, 92, 92);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80vw;
}

.episode {
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  align-items: center;
  max-lines: 2;
  width: 100%;
}

.episode img {
  height: 10rem;
}

.episodeTitles {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;
  color: lightgrey;
  margin-right: 1rem;
}

.episodeTitlesMobile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;
  color: lightgrey;
  margin-bottom: 1rem;
}

.episode-description {
  letter-spacing: 1px;
  white-space: pre-wrap;
  text-align: left;
  font-size: 13px;
  width: 100%;
}

.episodePlayButton {
  /* mask: url("./img/icons8-circled-play.svg");
  background-color: #00e3f3;
  padding: 3rem;
  margin-left: 2rem;
  border: 0;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center; */
  background-image: url("./img/icons8-play-button-circled.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 3rem;
  margin-left: 2rem;
  border: 0;
}

.episodePauseButton {
  mask: url("./img/icons8-pause-button.svg");
  background-color: #00e3f3;
  padding: 3rem;
  margin-left: 2rem;
  border: 0;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
}

.episodePlayButton:hover {
  opacity: 0.8;
  cursor: pointer;
  /* animation: bounce 0.4s; */
  transform: scale(1.1);
}

.episodePauseButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.descrption-button-down {
  mask: url("./img/icons8-expand-arrow.svg");
  background-color: #00e3f3;
  padding: 1rem;
  margin-left: auto;
  border: 0;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  justify-content: flex-end;
  margin-top: auto;
}

.descrption-button-down :hover {
  opacity: 0.8;
  cursor: pointer;
}

.descrption-button-up {
  mask: url("./img/icons8-expand-arrow.svg");
  background-color: #00e3f3;
  padding: 1rem;
  margin-left: auto;
  border: 0;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  transform: rotate(180deg);
  margin-top: auto;
}

.descrption-button-up :hover {
  opacity: 0.8;
  cursor: pointer;
}

.searchFeatures {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  /* margin-left: 20rem; */
  width: 80vw;
  margin-top: 2.5rem;
}

.searchOption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 25%;
  min-width: 20rem;
  margin-left: 2rem;
}

.selectOption {
  width: 100%;
  color: black;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 16px;
}

.bottomMargin {
  min-height: 8vh;
  background: #666a86;
  bottom: 0;
}

/* Episode Page */

.episodePage {
  margin: auto;
  margin-top: 5rem;
  width: 80%;
  border-bottom: 1px rgb(53, 52, 52) solid;
}

.episodePage .episode {
  margin-top: auto;
  align-items: flex-end;
}

.episodePage .episode img {
  width: 20rem;
  height: 20rem;
}

.episodePageMobile {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 5rem;
  width: 80%;
  border-bottom: 1px rgb(53, 52, 52) solid;
}

.episodePageMobile .episode {
  margin-top: auto;
  align-items: flex-end;
}

.episodePageMobile img {
  width: 100%;
  margin: auto;
}

.episodePageMobile .episodeTitles {}

.buttonAndHostAndShow {
  display: flex;
  margin-left: -2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Host Page */

.profileSection {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-top: 4rem;
  padding: 1rem;
  padding-bottom: 3rem;
  border-bottom: 1px rgb(53, 52, 52) solid;
}

.profileSection h3 {
  padding: 0px;
}

.profileSection img {
  width: 20rem;
  height: 20rem;
}

.profileSection p {
  font-weight: 200;
}

.profileDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 2rem;
}

.hostIcons {
  display: flex;
  margin-top: auto;
}

.hostIcons .socialIcons {
  margin-left: 0px;
  margin-right: 2rem;
}

/* Host Menu */

.hostMenuPage {
  min-height: 100vh;
  margin-top: 4rem;
  text-align: center;
}

.hostMenuPage p {
  text-align: left;
}

.hostMenuItem {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-top: 4rem;
  padding: 1rem;
  padding-bottom: 3rem;
  border-bottom: 1px rgb(53, 52, 52) solid;
}

@media only screen and (min-width: 200px) and (max-width: 450px) {
  .nav {
    flex-wrap: wrap-reverse;
  }
  .nav ul {
    justify-content: center;
  }
  #logo {
    margin-top: 1rem;
    justify-content: center;
  }
  .socialIcons {
    justify-content: center;
  }
  html {
    font-size: 40%;
  }
  .player-wrapper {
    min-height: 10vh;
    bottom: 0;
  }
  .hero h1 {
    font-size: 9rem;
  }
  /* .hero {
    background-size: 95%;
  } */
  .episodeCard {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .searchFeatures {
    margin-left: 6rem;
    margin-right: 6rem;
    justify-content: center;
  }
  .outsideLinks {
    flex-direction: column;
  }
  .profileSection {
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
  }
  .profileSection p {
    margin-top: 1rem;
  }
  .profileSection img {
    width: 10rem;
    height: 10rem;
  }
  .hostIcons {
    display: flex;
    margin-top: 1rem;
    margin-bottom: auto;
  }
  .site-header {
    min-height: 10vh;
  }
}

@media only screen and (min-width: 451px) and (max-width: 637px) {
  html {
    font-size: 45%;
  }
  .episodeCard {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .searchFeatures {
    margin-left: 8rem;
    margin-right: 8rem;
    justify-content: center;
  }
  /* .hero {
    background-size: 75%;
  } */
  .outsideLinks {
    flex-direction: column;
  }
}

@media only screen and (min-width: 638px) and (max-width: 873px) {
  .episodeCard {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .searchFeatures {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  /* .hero {
    background-size: 65%;
  } */
}

@media only screen and (min-width: 874px) and (max-width: 1200px) {
  /* .hero {
    background-size: 55%;
  } */
}